import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取所有模块
 * @param {} params
 */
export function getModuleList() {
  return request({
    url: envInfo.bgApp.tenantPath + '/module/findModuleList',
    method: 'get'
  })
}

/**
 * 获取已经开启的模块，包含租户模块信息
 * @param {} params
 */
export function _sys_findOpendModuleList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/module/findOpendModuleList',
    method: 'get',
    params
  })
}

/**
 * 开通模块
 * @param {*} params
 */
export function _sys_openModulx(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/module/openModule',
    method: 'post',
    data: params
  })
}

/**
 * 模块定价
 * @param {*} params
 */
export function _sys_savePricing(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/module/pricing',
    method: 'post',
    data: params
  })
}

/**
 * 关闭模块
 * @param {} params
 */
export function _sys_closeModulx(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/module/closeModule',
    method: 'post',
    data: params
  })
}

/**
 * 获取已经开启的模块，包含租户模块信息
 * @param {} params
 */
export function _tenant_findOpendModuleList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/openModule/findOpendModuleList',
    method: 'get',
    params
  })
}

/**
 * 开通模块
 * @param {*} params
 */
export function _tenant_openModulx(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/openModule/openModule',
    method: 'post',
    data: params
  })
}

/**
 * 是否开通了模块
 * @param {*} params 
 */
export function whetherOpenModule(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/openModule/whetherOpenModule',
    method: 'get',
    params
  })
}

