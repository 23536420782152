<template>
    <div class="app-container" v-loading="loading">
        <el-row>
            <template v-if="moduleList.length > 0">
                <el-col class="ruge-cards" :span="5" v-for="(modulx, index) in moduleList" :key="modulx.moduleCode" :offset="index % 4==0 ? 0 : 1">
                    <el-card>
                        <div :style="elCardIcon" slot="header">
                            <title-icon/><span>{{modulx.moduleName}}</span>
                        </div>
                        <div>
                            <template v-if="modulx.riseModule == 'Y'">
                                <span>{{$t('module.riseModule')}}</span>
                                <span style="float: right;">¥{{modulx.price}}/{{$t('module.unitPrice' + modulx.priceUnit)}}</span>
                            </template>
                            <template v-else-if="modulx.riseModule == 'N'">
                                <span>{{$t('module.freeModule')}}</span>
                            </template>
                            <template v-else>
                                <span>{{$t('module.unPricing')}} </span>
                            </template>
                            <div class="bottom">
                                <el-button @click="openPricing(modulx)" type="text">{{$t('module.pricing')}}</el-button>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </template>
            <template v-else>
                <div class="no-data">{{$t('commons.noData')}}</div>
            </template>
        </el-row>

        <el-dialog :visible.sync="pricingModal" :before-close="closePricing" :close-on-click-modal="false" class="app-dialog" width="500px">
            <template slot="title">
                <title-icon/>{{ $t('module.pricing') }}
            </template>
            <el-form :model="modulx" ref="modulxForm" :rules="modulx.formRule" label-width="120px">
                <el-form-item :label="$t('module.moduleName')" prop="moduleName">
                    <el-input maxlength="20" :show-word-limit="true" v-model="modulx.moduleName" name="moduleName" type="text"></el-input>
                </el-form-item>
                <el-form-item :label="$t('module.riseModule')" prop="riseModule">
                    <el-select v-model="modulx.riseModule" name="riseModule" @change="riseChange" :placeholder="$t('commons.selectPlease')">
                        <el-option
                        v-for="item in riseOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <template v-if="risePricing">
                    <el-form-item :label="$t('module.unitPrice')" prop="priceUnit">
                        <el-select v-model="modulx.priceUnit" name="priceUnit" :placeholder="$t('commons.selectPlease')">
                            <el-option
                            v-for="item in priceOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('module.price')" prop="price">
                        <el-input-number v-model="modulx.price" name="price" type="text" auto-complete="on"
                                    :placeholder="$t('commons.pleaseInput')" clearable autofocus>
                        </el-input-number>
                    </el-form-item>
                </template>
                <el-form-item :label="$t('module.comment')" prop="price">
                    <el-input maxlength="10" :show-word-limit="true" v-model="modulx.comment" name="comment" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="closePricing">{{$t('commons.cancel') }}</el-button>
                <el-button type="primary" @click="savePricing">{{$t('commons.save') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
    .ruge-cards .el-card {
        cursor: auto;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }
</style>

<script>

let modulxApi = require('@/api/ruge/tenant/modulx')

export default {
    data() {
        return {
            loading: true,
            moduleList: [],
            pricingModal: false,
            risePricing: true,
            modulx:{
                modulxCode: null,
                modulxName: null,
                riseModule: 'Y',
                priceUnit: '1',
                comment: null,
                formRule: {}
            },
            riseOption: [
                {value: 'Y', label: 'Y'},
                {value: 'N', label: 'N'}
            ],
            priceOption: [
                {value: '1', label: this.$t('module.unitPrice1')},
                {value: '2', label: this.$t('module.unitPrice2')},
                {value: '3', label: this.$t('module.unitPrice3')}
            ],
            elCardIcon: {
                "background": "url(" + require('@/assets/images/el-card-icon.png') + ") center right no-repeat",
            },
        };
    },
    created() {
        this.getAllModuleList()
    },
    methods: {
        getAllModuleList() {
            this.loading = true;
            modulxApi.getModuleList().then(rsp => {
                rsp.map(r=>{
                    r.moduleName = r.moduleName.length>15?r.moduleName.substring(0, 15)+"...":r.moduleName;
                });
                this.moduleList = rsp;
            }).finally(() => {
                this.loading = false;
            })
        },
        riseChange(riseOption) {
            if(riseOption && riseOption=='Y') {
                this.risePricing = true;
                this.modulx.priceUnit = '1';
            } else {
                this.risePricing = false;
            }
        },
        openPricing(modulx) {
            this.modulx = {moduleCode: modulx.moduleCode, moduleName: modulx.moduleName, riseModule: modulx.riseModule, priceUnit: modulx.priceUnit+'', price: modulx.price, comment: modulx.comment}
            this.pricingModal = true;
            this.$nextTick(()=>{
                this.riseChange(modulx.riseModule);
            })
        },
        closePricing() {
            this.pricingModal = false;
        },
        savePricing() {
            let modulx = this.modulx;
            if(modulx.riseModule == 'N') {
                modulx.priceUnit = null;
                modulx.price = null;
            }
            modulxApi._sys_savePricing(this.modulx).then(rsp => {
                if(rsp > 0) {
                    this.getAllModuleList();
                    this.closePricing();
                }
            })
        }
    }
}
</script>
