var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-row",
        [
          _vm.moduleList.length > 0
            ? _vm._l(_vm.moduleList, function (modulx, index) {
                return _c(
                  "el-col",
                  {
                    key: modulx.moduleCode,
                    staticClass: "ruge-cards",
                    attrs: { span: 5, offset: index % 4 == 0 ? 0 : 1 },
                  },
                  [
                    _c("el-card", [
                      _c(
                        "div",
                        {
                          style: _vm.elCardIcon,
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("title-icon"),
                          _c("span", [_vm._v(_vm._s(modulx.moduleName))]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          modulx.riseModule == "Y"
                            ? [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("module.riseModule"))),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { float: "right" } },
                                  [
                                    _vm._v(
                                      "¥" +
                                        _vm._s(modulx.price) +
                                        "/" +
                                        _vm._s(
                                          _vm.$t(
                                            "module.unitPrice" +
                                              modulx.priceUnit
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            : modulx.riseModule == "N"
                            ? [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("module.freeModule"))),
                                ]),
                              ]
                            : [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("module.unPricing")) + " "
                                  ),
                                ]),
                              ],
                          _c(
                            "div",
                            { staticClass: "bottom" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openPricing(modulx)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("module.pricing")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                )
              })
            : [
                _c("div", { staticClass: "no-data" }, [
                  _vm._v(_vm._s(_vm.$t("commons.noData"))),
                ]),
              ],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "app-dialog",
          attrs: {
            visible: _vm.pricingModal,
            "before-close": _vm.closePricing,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.pricingModal = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("module.pricing")) + "\n        "),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "modulxForm",
              attrs: {
                model: _vm.modulx,
                rules: _vm.modulx.formRule,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("module.moduleName"),
                    prop: "moduleName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": true,
                      name: "moduleName",
                      type: "text",
                    },
                    model: {
                      value: _vm.modulx.moduleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.modulx, "moduleName", $$v)
                      },
                      expression: "modulx.moduleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("module.riseModule"),
                    prop: "riseModule",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        name: "riseModule",
                        placeholder: _vm.$t("commons.selectPlease"),
                      },
                      on: { change: _vm.riseChange },
                      model: {
                        value: _vm.modulx.riseModule,
                        callback: function ($$v) {
                          _vm.$set(_vm.modulx, "riseModule", $$v)
                        },
                        expression: "modulx.riseModule",
                      },
                    },
                    _vm._l(_vm.riseOption, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.risePricing
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("module.unitPrice"),
                          prop: "priceUnit",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              name: "priceUnit",
                              placeholder: _vm.$t("commons.selectPlease"),
                            },
                            model: {
                              value: _vm.modulx.priceUnit,
                              callback: function ($$v) {
                                _vm.$set(_vm.modulx, "priceUnit", $$v)
                              },
                              expression: "modulx.priceUnit",
                            },
                          },
                          _vm._l(_vm.priceOption, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: _vm.$t("module.price"), prop: "price" },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            name: "price",
                            type: "text",
                            "auto-complete": "on",
                            placeholder: _vm.$t("commons.pleaseInput"),
                            clearable: "",
                            autofocus: "",
                          },
                          model: {
                            value: _vm.modulx.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.modulx, "price", $$v)
                            },
                            expression: "modulx.price",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("module.comment"), prop: "price" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "10",
                      "show-word-limit": true,
                      name: "comment",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.modulx.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.modulx, "comment", $$v)
                      },
                      expression: "modulx.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closePricing } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savePricing } },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }